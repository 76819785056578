<template>
  <v-app>
    <h4 class="mb-10 font-weight-bold text-dark">Select programme you wish to apply</h4>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <v-select   @change="getLevel" label="Level" :items="levels" outlined dense item-value="id" item-text="title"   v-model="enrollment.level_id"></v-select>
          <span class="form-text text-danger" v-if="$v.enrollment.level_id.$error">This information is required</span>
        </div>
      </div>
      <!-- <pre>{{level}}</pre> -->
      <div
          class="col-12"
          v-if="level.level == 'masters' || level.level == 'bachelors' || level.level == '+2'"
      >
        <div class="form-group">
          <label for>Program</label>
          <v-select @change="onSelectProgram" :items="programs" outlined dense label="Program" item-value="id" item-text="title_text"   v-model="enrollment.program_id"></v-select>
          <span
              class="form-text text-danger"
              v-if="(level.level == 'bachelors' || level.level == 'masters') && $v.enrollment.program_id.$error"
          >Program is required</span>
        </div>
        <div class="form-group" v-if="enrollment.program_id">
<!--          <b-form-checkbox
              id="checkbox-1"
              @change="enableSaveButton"

              value="accepted"
              unchecked-value="not_accepted"
              class="font-size-lg"
          >
            <span
                class="font-size-lg"
                @click="openDialog"
            >I declare the information supplied in this application and the documentation supporting it is correct and complete.*</span>
          </b-form-checkbox>-->
          <v-checkbox  @click="enableSaveButton" name="checkbox-1">
            <template v-slot:label>
              <span @click="openDialog">
                I declare the information supplied in this application and the documentation supporting it is correct and complete.*
              </span>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <eligibility-component ref="eligibility" :program="program"></eligibility-component>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import EligibilityComponent from "./Eligibility";

const levelService = new LevelService();
const programService = new ProgramService();
export default {
  name: "applying-for",
  props: ["enrollment", "enable_save"],
  validations() {
    return {
      enrollment: this.rules
    };
  },
  components: {
    EligibilityComponent
  },
  data() {
    return {
      levels: [],
      programs: [],
      level: {},
      program: {}
    };
  },
  computed: {
    rules() {
      let rule = {
        level_id: {required}
      };
      if (
          this.level.level == "masters" ||
          this.level.level == "bachelors" ||
          this.level.level == "+2"
      ) {
        rule.program_id = {required};
      }
      return rule;
    }
  },
  mounted() {
    this.getLevels();
  },
  updated() {
    if (!this.level.id && this.enrollment.program_id) {
      this.getLevel();
    }
  },
  methods: {
    validateForm() {
      this.$v.enrollment.$touch();
      if (this.$v.enrollment.$error) {
        setTimeout(() => {
          this.$v.enrollment.$reset();
        }, 5000);
      } else {
        this.$emit("validated");
      }
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        this.getLevel();
      });
    },
    getLevel() {
      if (!this.enrollment.level_id)
        return false;
      levelService.show(this.enrollment.level_id).then(response => {
        this.level = response.data.level;
        this.getPrograms();
      });
    },
    getPrograms() {
      if (
          this.level.level == "masters" ||
          this.level.level == "bachelors" ||
          this.level.level == "+2"
      ) {
        this.$emit("toogle_overlay");
        programService.getByLevelId(this.enrollment.level_id).then(response => {
          this.programs = response.data;
          this.$emit("toogle_overlay");
        });
      }
    },
    onSelectProgram(e) {
      programService.show(this.enrollment.program_id).then(response => {
        let program = response.data.program;
        this.program = program;
        this.enrollment.program_entrance_date = program.entrance_date;
        this.enrollment.program = program.abstract;
        this.$emit("program_selected", program);
        if(this.program.pre_registration_id){
          this.enrollment.pre_registration_id=this.program.pre_registration_id;
        }
      });
    },
    enableSaveButton() {
      this.$emit("enable_save_button");
    },
    closeModal() {
      this.$refs["eligibility"].showModal();
    },
    openDialog() {
      this.$refs["eligibility"].showModal();
    }
  }
};
</script>
