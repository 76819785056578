<template>
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">Applicant's Education Background</h4>
    <div class="row mt-5">
      <div class="col-12">
        <div class="form-group">
          <v-select
              label="Examination Board"
              outlined dense
              :items="education_backgrounds"
              v-model="education.board"
              item-text="title"
              item-value="title"
          >
          </v-select>
          <span
              class="form-text text-danger"
              v-if="$v.education.board.$error"
          >Please select a qualification to add your qualification. You can add multiple qualifications that you may</span>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-group">
              <div class="form-group">
                <v-text-field
                    type="text"
                    outlined dense
                    label="University/Institution"
                    v-model="education.institution_name"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <v-select
                  label="Start Year"
                  outlined dense
                  :items="years"
                  v-model="education.start_year"
              >
              </v-select>

              <span
                  class="form-text text-danger"
                  v-if="$v.education.start_year.$error"
              >Year passed cannot be empty</span>
            </div>
          </div>
          <!-- <pre>{{years}}</pre> -->
          <div class="col-6">
            <div class="form-group">
              <v-select
                  label="Completed Year"
                  outlined dense
                  :items="years"
                  v-model="education.pass_year"
              >
              </v-select>
              <span
                  class="form-text text-danger"
                  v-if="$v.education.pass_year.$error"
              >Year passed cannot be empty</span>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <div class="form-group">
                <v-text-field
                    outlined dense
                    type="text"
                    label="Mark Obtain"
                    v-model="education.mark_obtain"
                ></v-text-field>
                <span
                    class="form-text text-danger"
                    v-if="$v.education.mark_obtain.$error"
                >Mark obtain cannot be empty</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <div class="form-group">
                <v-select
                    label="Marks Type"
                    outlined dense
                    :items="marksType"
                    v-model="education.mark_type"
                >
                </v-select>
                <span
                    class="form-text text-danger"
                    v-if="$v.education.mark_type.$error"
                >Mark Type cannot be empty</span>
              </div>
            </div>
          </div>

          <div class="col-12" v-if=" education.board == '+2'">
            <div class="form-group">
              <v-select
                  label="Program"
                  outlined dense
                  :items="programs"
                  v-model="education.program"
                  item-value="value"
                  item-text="title"
              >
              </v-select>
            </div>
          </div>
          <div
              class="col-12"
              v-if="education.board == 'bachelors' || education.board == 'masters' ||education.board == 'others'"
          >
            <div class="form-group">
              <v-text-field
                  outlined dense
                  type="text"
                  v-model="education.program"
                  label="Program"
              ></v-text-field>
              <span
                  class="form-text text-danger"
                  v-if="(education.board == 'others') && $v.education.program.$error"
              >Program is required</span>
            </div>
          </div>
          <div class="col-12" v-if="education.board =='bachelors' || education.board =='masters'">
            <div class="form-group">
              <v-select
                  label="University"
                  outlined dense
                  :items="universities"
                  v-model="education.board"
                  item-value="title"
                  item-text="title"
              >
              </v-select>
              <span
                  class="form-text text-danger"
                  v-if="(education.board =='bachelors' || education.board =='masters') && $v.education.university.$error"
              >Please select university</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right mt-2">
          <a href="#" class="btn btn-lg btn-outline-primary" @click.prevent="createEducation">

            Save & Add
          </a>
        </div>
      </div>
    </div>
    <div v-if="educations.length>0">
      <h4>Added Qualifications</h4>
      <div class v-for="(edu,index) of educations" :key="index">
        <b-button
            v-b-toggle
            :href="`#collapse-${index}`"
            class="col-md-12 mt-3 bg-white border border-primary text-black font-size-lg font-weight-bold text-left"
        >{{ edu.board.toUpperCase() }}
        </b-button>

        <b-collapse :id="`collapse-${index}`" class="col-md-12">
          <table class="table table-striped col-md-12">
            <tr>
              <th>Level</th>
              <td>{{ edu.board.toUpperCase() }}</td>
            </tr>
            <tr>
              <th v-if="edu.program">Program</th>
              <td v-if="edu.program">{{ edu.program_text.toUpperCase() }}</td>
            </tr>
            <tr>
              <th>Start Year</th>
              <td>{{ edu.start_year }}</td>
            </tr>
            <tr>
              <th>Pass Year</th>
              <td>{{ edu.pass_year }}</td>
            </tr>
            <tr>
              <th>Mark Obtain</th>
              <td>
                {{ edu.mark_obtain }}
                <span v-if="edu.mark_type">{{ edu.mark_type.toUpperCase() }}</span>
              </td>
            </tr>
            <tr>
              <th v-if="edu.university">University</th>
              <td v-if="edu.university">{{ edu.university.toUpperCase() }}</td>
            </tr>
            <tr v-if="edu.institution_name">
              <th>University/Institution</th>
              <td>{{ edu.institution_name ? edu.institution_name.toUpperCase() : 'N/A' }}</td>
            </tr>
          </table>
        </b-collapse>
      </div>
    </div>
    <!-- <pre>{{education}}</pre> -->
  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserEducationService from "@/core/services/user/UserEducationService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import InstitutionService from "@/core/services/institution/InstitutionService";
import EducationService from "@/core/services/education/EducationService";

const levelService = new LevelService();
const programService = new ProgramService();
const educationService = new UserEducationService();
const institutionService = new InstitutionService();
const educationBackground = new EducationService();

export default {
  name: "education",
  props: ["enrollment"],
  validations() {
    return {
      education: this.rules
    };
  },
  data() {
    return {
      levels: [],
      universities: [],
      programs: [
        {value: "plus_two_humanities", title: "Humanities"},
        {value: "plus_two_management", title: "Management"},
        {value: "plus_two_science", title: "Science"},
        {value: "plus_two_science", title: "Other"},
      ],
      marksType: ["Percentage", "Grade", "GPA"],
      education_backrgound: {},
      education: {
        board: null,
        start_year: null,
        pass_year: null,
        mark_obtain: null,
        mark_type: null,
        institution_name: null,
        university: null,
        program: null
      },
      educations: [],
      education_backgrounds: []
    };
  },
  computed: {
    years() {
      let currentYear = new Date().getFullYear();
      let array = [];
      for (let i = 1; i <= 40; i++) {
        array.push(currentYear - i);
      }
      return array;
    },
    rules() {
      let rule = {
        board: {required},
        pass_year: {required},
        start_year: {required},
        mark_obtain: {required},
        mark_type: {required}
      };
      if (
          this.education.board == "masters" ||
          this.education.board == "bachelors"
      ) {
        rule.university = {required};
        rule.program = {required};
      }
      if (this.education.board == "+2" || this.education.board == "others") {
        rule.program = {required};
      }
      return rule;
    }
  },
  mounted() {
    // this.getLevels();
    this.getUniversities();
    this.getEducations();
    this.getEducationBackground();
  },
  methods: {
    validateForm() {
      this.$v.education.$touch();
      if (this.$v.education.$error) {
        setTimeout(() => {
          this.$v.education.$reset();
        }, 5000);
        return false;
      } else {
        // this.$emit("validated");
        return true;
      }
    },
    resetEducation() {
      this.education = {
        board: null,
        pass_year: null,
        mark_obtain: null,
        university: null
      };
    },
    // getLevels() {
    //   levelService.all().then(response => {
    //     this.levels = response.data;
    //   });
    // },
    getUniversities() {
      institutionService.all().then(response => {
        this.universities = response.data;
      });
    },
    getPrograms() {
      if (
          this.enrollment.level == "masters" ||
          this.enrollment.level == "bachelors"
      ) {
        programService.getByLevel(this.enrollment.level_id).then(response => {
          this.programs = response.data;
        });
      }
    },
    getEducations() {
      educationService
          .paginate(this.enrollment.id)
          .then(response => {
            this.educations = response.data.data;
          })
          .catch(error => {
            //console.log(error);
          });
    },
    getEducationBackground() {
      educationBackground
          .getByLevel(this.enrollment.level_id)
          .then(response => {
            this.education_backgrounds = response.data;
          });
    },
    createEducation() {
      if (!this.validateForm()) return false;
      this.$emit("toogle_overlay");
      educationService
          .store(this.enrollment.id, this.education)
          .then(response => {
            this.resetEducation();
            this.$v.education.$reset();
            this.getEducations();
            this.$emit("toogle_overlay");
          })
          .catch(error => {
            //console.log(error);
          });
    },
    onSelectEducation(educationBackground) {
      this.education_backrgound = educationBackground;
    }
  }
};
</script>
