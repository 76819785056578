<template>
    <div>
        <h4 class="mb-10 font-weight-bold text-dark">Declaration</h4>

        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12" v-if="!enrollment_status.is_complete">
                        <h4>Your form cannot be submitted due to the following reasons</h4>
                        <div class="alert alert-danger mr-2">
                            <i class="fas fa-ban"></i>
                            {{enrollment_status.msg}}
                        </div>
                    </div>
                    <div class="col-12" v-else>

                        <div class="alert alert-primary">
                            <i class="fas fa-check text-success mr-2"></i>
                            Your form entry is valid. You can now submit your application.
                        </div>
                        <h3></h3>

                        <p class="font-size-lg">I wish to be considered for admission to the programme at KIST College & SS and
                            request for issuance of an Exam Entry Card.</p>
                        <ul class="font-size-lg">
                            <li> I declare the information supplied in this application and the documentation supporting
                                it is correct and complete.
                            </li>
                            <li> I acknowledge that the provision of false or misleading information may result in the
                                non-acceptance of this application or immediate expulsion from the College after
                                admission.
                            </li>
                            <li> I authorize the College to verify any information provided by me.</li>
                            <li> I authorize the College to obtain, where necessary, from any other educational
                                institution evidence of my academic record or to seek other supporting evidence with
                                respect to my application.
                            </li>

                        </ul>
                        <p>
                        <div class="form-group">
                      <v-checkbox
                          label="I declare the information supplied in this application and the documentation supporting it is correct and complete.*"
                          value="accepted"
                          v-model="acceptTerms"
                          @change="checkTerms"
                      ></v-checkbox>
<!--                            <b-form-checkbox-->
<!--                                    id="checkbox-1"-->
<!--                                   -->
<!--                                    v-model="acceptTerms"-->
<!--                                    name="checkbox-1"-->
<!--                                    value="accepted"-->
<!--                                    unchecked-value="not_accepted"-->
<!--                                    class="font-size-lg"-->
<!--                            ><span class="font-size-lg"> </span>-->
<!--                            </b-form-checkbox>-->
                        </div>
                        </p>


                    </div>
                </div>
            </div>
        </div>
        <!-- <pre>{{enrollment_status}}</pre> -->
    </div>
</template>
<script>
    import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

    const enrollmentService = new UserEnrollmentService();
    export default {
        name: "summary-component",
        props: ["enrollment_status"],
        data() {
            return {
                acceptTerms: 'not_accepted',
            }
                ;
        },
        methods: {
            checkTerms() {
                if (this.acceptTerms == 'accepted') {
                    this.$emit("validated");
                }else{
                  this.$emit("invalid");
                }
            }
        },
        mounted() {
            //     localStorage.removeItem("enrollment");
            //     this.getEnrollmentStatus();
        }
    };
</script>
