<template>
  <div>
    <b-modal id="modal-scrollable" size="md" ref="my-modal" hide-footer title="Elgibility">
      <div class="row">
        <div class="col-12 form-group" v-html="program.admission_eligibility">        </div>
      </div>
      <div class="row">
        <div class="col-6">
      
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
      props:["program"],
       data() {
      return {

      };
    },mounted(){
    },
    methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    }
  }
};
</script>

      