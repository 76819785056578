<template>
    <div>
        <h4 class="mb-10 font-weight-bold text-dark">Attach Documents</h4>

        <p class="text-primary">Please attach following documents</p>
        <div v-for="(dtype, index) of documents_types" :key="index">

            <div class="row" v-if="!uploadedDocuments.includes(dtype)">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <label for="">{{dtype}}</label>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <!-- Styled -->
                    <v-file-input
                        outlined dense
                            v-model="file"
                            accept="application/msword ,application/pdf, image/*, application/vnd/*"
                            @change="checkSize($event,index)"
                            label="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                    ></v-file-input>
                    <!-- <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div> -->

                </div>

                <div class="col-md-2 col-lg-2 col-sm-2">
                    <v-btn
                            :disabled="file==null || file!=undefined && selectedIndex!=index"
                            href="#"
                            class="btn btn-lg btn-outline-primary  "
                            @click.prevent="uploadDocuments(dtype)"
                    >
                        <i class="fa fa-upload"></i>
                        Upload
                    </v-btn>
                </div>

            </div>
        </div>
        <span class="text-danger" v-if="sizeExceed">File size must be less than 20Mb</span>

        <br>

        <span class="text-primary" v-if="documents.length>0">Submited Documents</span>

        <div class="row mt-5" v-if="documents.length>0">
            <div class="col-md-3 col-sm-6  p-2  " v-for="(doc, i) of documents" :key="i">
                <div class="bg-secondary p-2 rounded">
                    <img v-if="checkFileType(doc.image_path.thumb)" :src="doc.image_path.thumb" alt
                         class="img-thumbnail"/>
                    <img v-else src="/media/document.png" alt
                         class="img-thumbnail"/>
                    <p class="p-2">{{doc.photo_type}}
                        <span class="float-right cursor-pointer" @click="deletePhoto(doc)"><i class="fas fa-trash "></i></span>
                    </p>
                </div>

            </div>
        </div>
        <!--        <div class="col-md-12 text-right mt-2">-->
        <!--            <a-->
        <!--                    :disabled="sizeExceed"-->
        <!--                    href="#"-->
        <!--                    class="btn btn-lg btn-outline-primary  "-->
        <!--                    @click.prevent="uploadDocuments"-->
        <!--            >-->
        <!--                <i class="fa fa-upload"></i>-->
        <!--                Upload & Save-->
        <!--            </a>-->
        <!--        </div>-->
    </div>
</template>
<script>
    import UserDocumentService from "@/core/services/user/UserDocumentService";
    import ProgramService from "@/core/services/level/program/ProgramService";


    const documentService = new UserDocumentService();
    const programService = new ProgramService();

    export default {
        name: "documents",
        props: ["enrollment"],

        data() {
            return {
                file: null,
                type: null,
                sizeExceed: false,
                selectedIndex: null,
                imageFiles: ['jpg', 'jpeg', 'png', 'gif'],
                documents: [],
                uploadedDocuments: [],
                neddedDocuments: [],
                documents_types: [],
                document: {
                    image: null,
                    type: null
                }
            };
        },
        mounted() {
            this.getDocuments();
            this.getDocumentTypes();
        },
        methods: {
            checkFileType(file) {
                let fileExtension = file.split('.')[2];
                let image = (this.imageFiles.indexOf(fileExtension) > -1)
                return image;
            },
            checkList() {
                this.documents.forEach((ele) => {
                    this.uploadedDocuments.push(ele.type);
                })
            },
            getDocuments() {
                documentService
                    .paginate(this.enrollment.id)
                    .then(response => {
                        this.documents = response.data.data;
                        this.checkList();
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            }, getDocumentTypes() {
                programService.show(this.enrollment.program_id).then(response => {
                    let program = response.data.program
                    this.documents_types = program.required_documents
                })
            },
            checkSize(file, index) {
                this.selectedIndex = index;
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            uploadDocuments(title) {
                let fd = new FormData();
                fd.append("image", this.file);
                fd.append("type", title)
                this.$emit("toogle_overlay");
                documentService
                    .store(this.enrollment.id, fd)
                    .then(response => {
                        this.file = null;
                        this.getDocuments();
                        this.$emit("toogle_overlay");
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            }, deletePhoto(doc) {

                this.$confirm({
                    message: `Are you sure you want to delete this item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            documentService
                                .delete(this.enrollment.id, doc.id)
                                .then(response => {
                                    this.file = null;
                                    this.getDocuments();
                                    this.$snotify.success("Information deleted");
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });

            }
        }
    };
</script>
