<template>
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">Payment Method</h4>
    <div class="row">
      <div class="col-xl-6">
        <p>Select this method to pay online</p>
        <input
          type="radio"
          v-model="info.payment_method"
          name="payment_method"
          class="form-check-input"
          id="esewa"
          value="esewa"
        />
        <label for="esewa">
          <img width="200px"
              src="https://www.techlekh.com/wp-content/uploads/2017/06/esewa-logo.png"
              class="payment_image"
          />
        </label>

      </div>
<!--      <div class="col-xl-12">-->

<!--        <input-->
<!--          type="radio"-->
<!--          v-model="info.payment_method"-->
<!--          name="payment_method"-->
<!--          class="form-check-input"-->
<!--          id="khalti"-->
<!--          value="viber"-->
<!--        />-->
<!--        <label for="khalti">-->
<!--          <img-->
<!--              src="https://upload.wikimedia.org/wikipedia/en/f/fd/Khalti_Digital_Wallet_Logo.png"-->
<!--              class="payment_image"-->
<!--          />-->
<!--        </label>-->
<!--      </div>-->
      <div class="col-xl-6">
        <p>Select this method to submit form and pay at college premise.</p>
        <input
          type="radio"
          v-model="info.payment_method"
          name="payment_method"
          class="form-check-input"
          id="cash"
          value="cash"
        />
        <label for="cash">
          <img
                  width="200px"
              src="https://e7.pngegg.com/pngimages/178/28/png-clipart-computer-icons-money-bag-bank-cash-angle-hand-thumbnail.png"
              class="payment_image"
          />
        </label>

      </div>
    </div>
    <!-- <pre>{{info}}</pre>
    <pre>{{enrollment}}</pre>-->
  </div>
</template>
<script>
import UserInfoService from "@/core/services/user/UserInfoService";
const infoService = new UserInfoService();

export default {
  name: "payment-method",
  props: ["enrollment"],
  data() {
    return {
      info: {
        payment_method: "cash"
      }
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      infoService.getByUser(this.enrollment.id).then(response => {
        if (response.data.info) {
          this.info = response.data.info;
        }
      });
    },
    createOrUpdate() {
      if (this.info && this.info.id) {
        this.updateInfo();
      } else {
        this.createInfo();
      }
    },
    createInfo() {
      this.$emit("toogle_overlay");
      infoService.store(this.enrollment.id, this.info).then(response => {
        if (response.data.info) this.info = response.data.info;
        this.$emit("toogle_overlay");
        this.$emit("validated");
      });
    },
    updateInfo() {
      this.$emit("toogle_overlay");
      infoService
        .update(this.enrollment.id, this.info.id, this.info)
        .then(response => {
          if (response.data.info) this.info = response.data.info;
          this.$emit("toogle_overlay");
          this.$emit("validated");
        });
    }
  }
};
</script>
