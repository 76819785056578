<template>
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">Additional Information</h4>
    <div class="form-group mb-5">
      <b-form-group class="form-control-solid form-control-lg">
        <h5 class="font-weight-bold text-dark">Were you offered any scholarship in the past?</h5>
        <b-form-radio v-model="info.scholarship_offered" name="scholarship_offered" value="1">Yes</b-form-radio>
        <b-form-radio v-model="info.scholarship_offered" name="scholarship_offered" value="0">No</b-form-radio>
      </b-form-group>
    </div>
    <br />
    <div class="form-group mb-5 mt-5" v-if="enrollment.level=='+2'">
      <b-form-group class="form-control-solid form-control-lg">
        <h5 class="font-weight-bold text-dark">Please select your course of interest</h5>
        <b-form-radio
          v-model="info.interested_subject"
          name="interested_subject"
          value="biology"
        >Biology</b-form-radio>
        <b-form-radio
          v-model="info.interested_subject"
          name="interested_subject"
          value="computer_science"
        >Computer Science</b-form-radio>
      </b-form-group>
      <br />
    </div>
    <div class="form-group mb-5 mt-5" v-if="enrollment.level=='+2'">
      <b-form-group label class="form-control-solid form-control-lg">
        <h5 class="font-weight-bold text-dark">Which class time schedule you are looking after ?</h5>
        <b-form-radio v-model="info.class_shift" name="class_shift" value="day">Day</b-form-radio>
        <b-form-radio v-model="info.class_shift" name="class_shift" value="morning">Morning</b-form-radio>
      </b-form-group>
      <br />
    </div>
    <div class="form-group mb-5 mt-5">
      <b-form-group label class="form-control-solid form-control-lg">
        <h5
          class="font-weight-bold text-dark"
        >Has the applicant repeated any class or grade or level?</h5>
        <b-form-radio v-model="info.grade_repeated" name="grade_repeated" value="1">Yes</b-form-radio>
        <b-form-radio v-model="info.grade_repeated" name="grade_repeated" value="0">No</b-form-radio>
      </b-form-group>
    </div>
    <br />
    <div class="form-group mb-5 mt-5 space-button-mobile">
      <b-form-group label class="form-control-solid form-control-lg">
        <h5
          class="font-weight-bold text-dark"
        >Does the applicant have any mental, psychological or physical health condition or disability about which the College should be aware?</h5>
        <b-form-radio v-model="info.has_health_issues" name="has_health_issues" value="1">Yes</b-form-radio>
        <b-form-radio v-model="info.has_health_issues" name="has_health_issues" value="0">No</b-form-radio>
      </b-form-group>
    </div>
    <br />
    <div class="form-group mb-5 mt-5 mt-sx-5">
      <b-form-group label class="form-control-solid form-control-lg">
        <h5 class="font-weight-bold text-dark">Does the applicant routinely take medication?</h5>
        <b-form-radio v-model="info.routine_medicine" name="routine_medicine" value="1">Yes</b-form-radio>
        <b-form-radio v-model="info.routine_medicine" name="routine_medicine" value="0">No</b-form-radio>
      </b-form-group>
    </div>
    <br />
    <div class="form-group mb-5 mt-5">
      <b-form-group label class="form-control-solid form-control-lg">
        <h5 class="font-weight-bold text-dark">Does the applicant have special needs?</h5>
        <b-form-radio v-model="info.special_needs" name="special_needs" value="1">Yes</b-form-radio>
        <b-form-radio v-model="info.special_needs" name="special_needs" value="0">No</b-form-radio>
      </b-form-group>
    </div>
    <br />
    <div class="form-group mb-5 mt-5">
      <b-form-group label class="form-control-solid form-control-lg">
        <h5 class="font-weight-bold text-dark">Do you need residential facility?</h5>
        <b-form-radio v-model="info.residential_facility" name="residental_facility" value="1">Yes</b-form-radio>
        <b-form-radio v-model="info.residential_facility" name="residental_facility" value="0">No</b-form-radio>
      </b-form-group>
    </div>
    <br />
    <div class="form-group mb-5 mt-5">
      <b-form-group label class="form-control-solid form-control-lg">
        <h5 class="font-weight-bold text-dark">Do you need transportation facility?</h5>
        <b-form-radio v-model="info.transport_facility" name="tranport_facility" value="1">Yes</b-form-radio>
        <b-form-radio v-model="info.transport_facility" name="tranport_facility" value="0">No</b-form-radio>
      </b-form-group>
    </div>
    <!-- <pre>{{questionnaires}}</pre> -->
    <div class="row mt-5" v-if="questionnaires.length>0">
      <div class="col-12 mb-5" v-for="(ques, index) of questionnaires" :key="index">
        
        <b-form-group class="form-control-solid form-control-lg">
          <h5 class="font-weight-bold text-dark">{{ques.title}}</h5>
          <!-- radio -->
          <div v-if="ques.type == 'radio' && ques.values.length>0">
            <b-form-radio
              :id="`radio-${i}-${ques.id}`"
              v-for="(v,i) of ques.values"
              :key="i"
              :name="`radio-${ques.id}`"
              :value="v"
              v-model="infos[index].value"
            >{{v}}</b-form-radio>
          </div>
          <!-- checkbox -->
          <div v-if="ques.type == 'checkbox' && ques.values.length>0">
            <b-form-checkbox
              v-for="(v,i) of ques.values"
              :key="i"
              :id="`checkbox-${i}-${ques.id}`"
              :name="`checkbox-${i}-${ques.id}`"
              :value="v"
              v-model="infos[index].value"
              unchecked-value="not_accepted"
            >{{v}}</b-form-checkbox>
          </div>
          <div v-if="ques.type == 'input'">
            <b-form-input :placeholder="ques.title" v-model="infos[index].value"></b-form-input>
          </div>
          <div v-if="ques.type == 'textarea'">
            <b-form-textarea id="textarea" :placeholder="ques.title" v-model="infos[index].value"></b-form-textarea>
          </div>
          <div v-if="ques.type == 'select' && ques.values.length>0">
            <b-form-select
              :options="ques.values"
              size="sm"
              class="mt-3"
              :placeholder="ques.title"
              v-model="infos[index].value"
            ></b-form-select>
          </div>
        </b-form-group>
      </div>
    </div>
    <!-- <pre>{{infos}}</pre>
    <pre>{{info}}</pre> -->
  </div>
</template>
<script>
import UserInfoService from "@/core/services/user/UserInfoService";
import QuestionnaireService from "@/core/services/level/program/questionnaire/QuestionnaireService";

const infoService = new UserInfoService();
const questionnaireService = new QuestionnaireService();

export default {
  name: "additional-information",
  props: ["enrollment"],
  data() {
    return {
      questionnaires: [],
      infos: [],
      info: {
        scholarship_offered: 0,
        interested_subject: "computer_science",
        class_shift: "day",
        grade_repeated: 0,
        has_health_issues: 0,
        routine_medicine: 0,
        special_needs: 0,
        residential_facility: 0,
        transport_facility: 0,
        more_info: []
      }
    };
  },
  mounted() {
    this.getInfo();
    this.getQuestionnaire();
  },
  methods: {
    getInfo() {
      infoService.getByUser(this.enrollment.id).then(response => {
        if (response.data.info) {
          this.info = response.data.info;
          this.infos = this.info.more_info;
        }
      });
    },
    getQuestionnaire() {
      questionnaireService
        .getByProgram(this.enrollment.program_id)
        .then(response => {
          this.questionnaires = response.data;
          this.buildInfos();
        });
    },
    buildInfos() {
      let value = null;
      let title = null;
      let exist = false;
      this.questionnaires.forEach(ele => {
        value = null;
        title = ele.title;
        if (ele.type == "radio") {
          value = ele.values[0];
        } else if (ele.type == "select") {
          value = ele.values[0];
        } else {
          value = null;
        }
        if (this.info.more_info.lenght > 0) {
          this.info.mero_infos.forEach(e => {
            if (e.title == ele.title) {
              exist = true;
            }
          });
        }
        if (!exist) {
          this.infos.push({
            title: title,
            value: value
          });
        }
      });
    },
    createOrUpdate() {
      if (this.info.id) {
        this.updateInfo();
      } else {
        this.createInfo();
      }
    },
    createInfo() {
      this.info.more_info = [];
      this.info.more_info = this.infos;
      this.$emit("toogle_overlay");
      infoService.store(this.enrollment.id, this.info).then(response => {
        if (response.data.info) this.info = response.data.info;
        this.$emit("toogle_overlay");
        this.$emit("validated");
      });
    },
    updateInfo() {
      this.$emit("toogle_overlay");
      infoService
        .update(this.enrollment.id, this.info.id, this.info)
        .then(response => {
          if (response.data.info) this.info = response.data.info;
          this.$emit("toogle_overlay");
          this.$emit("validated");
        });
    }
  }
};
</script>

<style>
@media (min-width: 950px) {
  space-button-mobile {
    margin-bottom: 25px !important;
  }
}
</style>