<template>
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">Parent information</h4>
    <div>
      <div class="" v-if="parents.length>0">
        <div class="card-body">
          <div class="row card" >
            <table class="table table-striped" v-for="(p, index) of parents" :key="index">
              <tr>
                <th class="text-left">First name</th>
                <td class="text-left">{{ p.first_name }}</td>
              </tr>
              <tr>
                <th class="text-left">Middle name</th>
                <td class="text-left">{{ (p.middle_name) ? p.middle_name : '-' }}</td>
              </tr>
              <tr>
                <th class="text-left">Last name</th>
                <td class="text-left">{{ p.last_name }}</td>
              </tr>
              <tr>

                <th class="text-left">Relation</th>
                <td class="">
                  {{ p.relation ? p.relation.toUpperCase() : "-" }}
                  <span v-if="p.is_guardian">, GUARDIAN</span>
                </td>
              </tr>
              <tr>
                <th class="text-left">Profession</th>
                <td class="text-left">
                  {{ p.profession ? p.profession.toUpperCase() : "N/A" }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Phone</th>
                <td class="text-left">{{ p.phone }}</td>
              </tr>
              <tr>
                <th class="text-left">Mobile</th>
                <td class="text-left">{{ p.mobile }}</td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-left">{{ p.email }}</td>
              </tr>
              <tr>
                <th class="text-left">Action</th>
                <td class="text-left">
                  <p class="text-danger cursor-pointer" @click="_delete(p.id)">Remove parent</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="row mt-3" v-if="show_form">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5>Father information</h5>
              <div class="row mt-3">
                <div class="col-md-4">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="First name"
                        outlined dense
                        v-model="father.first_name"
                    ></v-text-field>
                    <span
                        class="form-text text-danger"
                        v-if="$v.father.first_name.$error"
                    >Please enter your first name.</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Middle name"
                        outlined dense
                        v-model="father.middle_name"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Last name"
                        outlined dense
                        v-model="father.last_name"
                    ></v-text-field>
                    <span
                        class="form-text text-danger"
                        v-if="$v.father.last_name.$error"
                    >Please enter your last name.</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Profession"
                        outlined dense
                        v-model="father.profession"
                    ></v-text-field>
                    <span
                        class="form-text text-danger"
                        v-if="$v.father.profession.$error"
                    >Please enter father profession.</span>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Phone"
                        outlined dense
                        v-model="father.phone"
                    ></v-text-field>
                    <span
                        class="form-text text-danger"
                        v-if="$v.father.phone.$error"
                    >Please enter phone number.</span>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col-xl-6">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Mobile"
                        outlined dense
                        v-model="father.mobile"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-text-field
                        type="email"
                        label="Email"
                        outlined dense
                        v-model="father.email"
                    ></v-text-field>
                  </div>
                </div>


              </div>
            </div>
            <hr>
            <div class="card-body">
              <h5>Mother information</h5>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <v-text-field
                        type="email"
                        label="First name"
                        outlined dense
                        v-model="mother.first_name"
                    ></v-text-field>
                    <span
                        class="form-text text-danger"
                        v-if="$v.mother.first_name.$error"
                    >Please enter your first name.</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Middle name"
                        outlined dense
                        v-model="mother.middle_name"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Last name"
                        outlined dense
                        v-model="mother.last_name"
                    ></v-text-field>
                    <span
                        class="form-text text-danger"
                        v-if="$v.mother.last_name.$error"
                    >Please enter your last name.</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <label></label>
                    <v-text-field
                        type="text"
                        label="Profession"
                        outlined dense
                        v-model="mother.profession"
                    ></v-text-field>

                    <span
                        class="form-text text-danger"
                        v-if="$v.mother.profession.$error"
                    >Please enter mother profession.</span>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Phone"
                        outlined dense
                        v-model="mother.phone"
                    ></v-text-field>

                    <span
                        class="form-text text-danger"
                        v-if="$v.mother.phone.$error"
                    >Please enter phone number.</span>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col-xl-6">
                  <div class="form-group">
                    <v-text-field
                        type="text"
                        label="Mobile"
                        outlined dense
                        v-model="mother.mobile"
                    ></v-text-field>

                    <!-- <span
                      class="form-text text-danger"
                      v-if="$v.mother.mobile.$error"
                    >Please enter mobile number.</span>-->
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-text-field
                        type="email"
                        label="Email"
                        outlined dense
                        v-model="mother.email"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <hr>

            <div class="card-body">
              <div class="row">
                <div class="col-xl-12">
                  <div class="col-xl-12">

                    <div class="form-group">
                      <b-form-checkbox
                          id="is_guardian-1"
                          v-model="localGuardian"
                          name="is_guardian-1"
                      >I have a local guardian.
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
             <div v-if="localGuardian">
               <h5>Local guardian information</h5>
               <div class="row">
                 <div class="col-md-4">
                   <div class="form-group">
                     <label>First name</label>
                     <input
                         type="text"
                         class="form-control form-control-solid form-control-lg"
                         name="fname"
                         placeholder="First name"
                         value
                         v-model="guardian.first_name"
                     />
                     <span
                         class="form-text text-danger"
                         v-if="$v.guardian.first_name.$error"
                     >Please enter your first name.</span>
                   </div>
                 </div>
                 <div class="col-md-4">
                   <div class="form-group">
                     <label>Middle name</label>
                     <input
                         type="text"
                         class="form-control form-control-solid form-control-lg"
                         name="mname"
                         placeholder="Middle name"
                         value
                         v-model="guardian.middle_name"
                     />
                   </div>
                 </div>
                 <div class="col-md-4">
                   <div class="form-group">
                     <label>Last name</label>
                     <input
                         type="text"
                         class="form-control form-control-solid form-control-lg"
                         name="lname"
                         placeholder="Last name"
                         value
                         v-model="guardian.last_name"
                     />
                     <span
                         class="form-text text-danger"
                         v-if="$v.guardian.last_name.$error"
                     >Please enter your last name.</span>
                   </div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-xl-6">
                   <div class="form-group">
                     <label>Profession</label>
                     <input
                         type="text"
                         class="form-control form-control-solid form-control-lg"
                         name="dob"
                         placeholder="Guardian profession"
                         value
                         v-model="guardian.profession"
                     />
                     <span
                         class="form-text text-danger"
                         v-if="$v.guardian.profession.$error"
                     >Please enter guardian profession.</span>
                   </div>
                 </div>

                 <div class="col-xl-6">
                   <div class="form-group">
                     <label>Phone</label>
                     <input
                         type="tel"
                         class="form-control form-control-solid form-control-lg"
                         name="phone"
                         placeholder="Phone"
                         value
                         v-model="guardian.phone"
                     />
                     <span
                         class="form-text text-danger"
                         v-if="$v.guardian.phone.$error"
                     >Please enter phone number.</span>
                   </div>
                 </div>
               </div>
               <div class="row">

                 <div class="col-xl-6">
                   <div class="form-group">
                     <label>Mobile</label>
                     <input
                         type="tel"
                         class="form-control form-control-solid form-control-lg"
                         name="phone"
                         placeholder="Mobile"
                         value
                         v-model="guardian.mobile"
                     />
                     <!-- <span
                       class="form-text text-danger"
                       v-if="$v.guardian.mobile.$error"
                     >Please enter mobile number.</span>-->
                   </div>
                 </div>
                 <div class="col-xl-6">
                   <div class="form-group">
                     <label>Email</label>
                     <input
                         type="email"
                         class="form-control form-control-solid form-control-lg"
                         name="email"
                         placeholder="Email"
                         value
                         v-model="guardian.email"
                     />
                   </div>
                 </div>

               </div>
             </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserParentService from "@/core/services/user/UserParentService";

const parentService = new UserParentService();

export default {
  name: "parental-information",
  props: ["enrollment"],

  validations: {
    father: {
      first_name: {required},
      last_name: {required},
      phone: {required},
      profession: {required},
    },
    mother: {
      first_name: {required},
      last_name: {required},
      phone: {required},
      profession: {required},
    },
    guardian: {
      first_name: {required},
      last_name: {required},
      phone: {required},
      profession: {required},
    }
  },
  data() {
    return {
      copy: null,
      parents: [],
      show_form: true,
      localGuardian:false,
      father: {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        profession: null,
        relation: "father",
        is_guardian: false
      },
      mother: {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        profession: null,
        relation: "mother",
        is_guardian: false
      },
      guardian: {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        profession: null,
        relation: "guardian",
        is_guardian: false
      }
    };
  },
  mounted() {
    this.getParentsInfo();
  },
  methods: {
    copyObject(type) {
      if (type == "father") {
        this.guardian = this.father;
      } else {
        this.guardian = this.mother;
      }
    },
    validateForm() {
    },
    resetParent() {
      this.father = {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        profession: null,
        relation: "father"
      };

      this.mother = {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        profession: null,
        relation: "mother"
      };

      this.guardian = {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        profession: null,
        relation: "guardian"
      }
    },
    getParentsInfo() {
      parentService.paginate(this.enrollment.id).then(response => {
        this.parents = response.data.data;
          this.show_form = !this.checkParent();


      });
    },
    createParentInfo() {
      this.$v.father.$touch();
      this.$v.mother.$touch();
      this.$v.guardian.$touch();

      if(this.localGuardian)
      {
        if (this.$v.father.$error || this.$v.mother.$error || this.$v.guardian.$error) {
          setTimeout(() => {
            this.$v.father.$reset();
            this.$v.mother.$reset();
            this.$v.guardian.$reset();
          }, 5000);
        } else {
          this.$emit("toogle_overlay");
          let parent = this.father;
          parent.mother = this.mother;
          parent.guardian = this.guardian;
          parentService.store(this.enrollment.id, parent).then(response => {
            this.$v.father.$reset();
            this.$v.mother.$reset();
            this.resetParent();
            this.getParentsInfo();
            this.$emit("toogle_overlay");
            this.$emit("validated");
          });
        }
      }
      else
      {
        if (this.$v.father.$error || this.$v.mother.$error) {
          setTimeout(() => {
           this.$v.father.$reset();
            this.$v.mother.$reset();
            this.$v.guardian.$reset();
          }, 5000);
        } else {
          this.$emit("toogle_overlay");
          let parent = this.father;
              parent.mother = this.mother;
          parentService.store(this.enrollment.id, parent).then(response => {
            this.$v.father.$reset();
            this.$v.mother.$reset();
            this.$v.guardian.$reset();
            this.resetParent();
            this.getParentsInfo();
            this.$emit("toogle_overlay");
            this.$emit("validated");
          });
        }
      }


    },

     checkParent() {
       if(this.localGuardian)
         return this.parents.length > 2;
       else
         return this.parents.length > 1;
     },

    _delete(id) {

      this.$confirm(
          {
            message: `Are you sure you want to delete this item?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                this.$emit("toogle_overlay");
                parentService.delete(this.enrollment.id, id).then(response => {
                  this.$snotify.success("Information deleted");

                  this.getParentsInfo();
                  this.$emit("toogle_overlay");
                });


              }
            }
          }
      )
    }

  }
};
</script>
