<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav -->
          <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
            <div class="wizard-steps">
              <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/wired/64/000000/1-circle-c.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Programme</h3>
                    <div class="wizard-desc"> Programme</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/carbon-copy/64/000000/2-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Applicant information</h3>
                    <div class="wizard-desc">Student details</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id ? true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/carbon-copy/100/000000/3-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Address</h3>
                    <div class="wizard-desc">Applicant address</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  href="#"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id?true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/carbon-copy/100/000000/4-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Parental information</h3>
                    <div class="wizard-desc">Parent/Guardian details</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id?true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-1x">
                      <img
                          src="https://img.icons8.com/carbon-copy/100/000000/5-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Education background</h3>
                    <div class="wizard-desc">Previous qualifications</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id?true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/carbon-copy/100/000000/6-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Additional information</h3>
                    <div class="wizard-desc">Tell us a bit more</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id?true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/carbon-copy/100/000000/7-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Document attachments</h3>
                    <div class="wizard-desc">Upload relevant documents</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id?true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/carbon-copy/100/000000/8-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Payment</h3>
                    <div class="wizard-desc">Admission charge</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id?true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/carbon-copy/100/000000/9-circle.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Entrance date</h3>
                    <div class="wizard-desc">Date/time</div>
                  </div>
                </div>
              </div>
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  :data-wizard-clickable="enrollment.id?true:false"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <img
                          src="https://img.icons8.com/wired/64/000000/circled-10.png"
                          class="wizard-icon-img"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Submission</h3>
                    <div class="wizard-desc">Review and submit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->

          <!--begin: Wizard Body -->
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="col-12 text-right" v-if="enrollment.program">
                <h4>
                  Program chosen
                  <span
                      class="badge badge-warning"
                  >{{ enrollment.program ? enrollment.program : 'Not Selected' }}</span>
                  <span class="ml-2" @click="openEligibility"><i class="fas fa-question"></i></span>
                </h4>
                <strong
                    v-if="enrollment.program_form_closing_date"
                >Entrance closing date: {{ enrollment.program_form_closing_date }}</strong>
              </div>
              <div class="col-xxl-12">
                <form class="form" id="kt_form">
                  <!--                <div class="d-flex justify-content-between pb-10">-->
                  <!--                  <div class="mr-2">-->
                  <!--                    <button-->
                  <!--                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"-->
                  <!--                        data-wizard-type="action-prev-top"-->
                  <!--                    >Previous</button>-->
                  <!--                  </div>-->
                  <!--                  <div>-->
                  <!--                    <button-->
                  <!--                        v-on:click="getEnrollmentStatus"-->
                  <!--                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"-->
                  <!--                        data-wizard-type="action-submit-top"-->
                  <!--                    >Submit</button>-->
                  <!--                    <button-->
                  <!--                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"-->
                  <!--                        data-wizard-type="action-next-top"-->
                  <!--                        @click.prevent="nextClicked"-->
                  <!--                    >Next Step</button>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--begin: Wizard Step 1-->
                  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <applying-for
                        v-if="user.id"
                        :enrollment="enrollment"
                        :enable_save="enable_save"
                        ref="applying_for"
                        @validated="proceedToNextStep"
                        @program_selected="setProgram"
                        @enable_save_button="enableSaveButton"
                    ></applying-for>
                  </div>
                  <div class="pb-5" data-wizard-type="step-content">
                    <personal-information
                        v-if="user.id"
                        :enrollment="enrollment"
                        ref="personal_detail"
                        @validated="proceedToNextStep"
                        @convertAdToBs="convertAdToBs"
                    ></personal-information>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <address-component
                        v-if="enrollment.id"
                        :enrollment="enrollment"
                        ref="address"
                        @validated="proceedToNextStep"
                        @toogle_overlay="toogleOverlay"
                    ></address-component>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <parental-information
                        v-if="enrollment.id"
                        :enrollment="enrollment"
                        ref="parental_info"
                        @validated="proceedToNextStep"
                        @toogle_overlay="toogleOverlay"
                    ></parental-information>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <education
                        v-if="enrollment.id"
                        :enrollment="enrollment"
                        ref="education"
                        @validated="proceedToNextStep"
                        @toogle_overlay="toogleOverlay"
                    ></education>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <additional-information
                        v-if="enrollment.id"
                        :enrollment="enrollment"
                        ref="additional_info"
                        @validated="proceedToNextStep"
                        @toogle_overlay="toogleOverlay"
                    ></additional-information>
                  </div>
                  <!--end: Wizard Step 5-->

                  <!--begin: Wizard Step 6-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <documents
                        v-if="enrollment.id"
                        :enrollment="enrollment"
                        ref="document"
                        @validated="proceedToNextStep"
                        @toogle_overlay="toogleOverlay"
                    ></documents>
                  </div>
                  <!--end: Wizard Step 6-->

                  <!--begin: Wizard Step 6-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <payment-method
                        v-if="enrollment.id"
                        :enrollment="enrollment"
                        ref="payment-method"
                        @validated="proceedToNextStep"
                        @toogle_overlay="toogleOverlay"
                    ></payment-method>
                  </div>
                  <!--end: Wizard Step 5-->

                  <!--begin: Wizard Step 6-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <exam-schedule
                        v-if="enrollment.id"
                        :enrollment="enrollment"
                        ref="exam-schedule"
                        @validated="proceedToNextStep"
                        @toogle_overlay="toogleOverlay"
                    ></exam-schedule>
                  </div>

                  <div class data-wizard-type="step-content">
                    <summary-component
                        @validated="checkedTerms"
                        @invalid="unchekedTerms"
                        :enrollment_status="enrollment_status"
                    ></summary-component>
                  </div>
                  <!--end: Wizard Step 6-->

                  <!--begin: Wizard Actions -->
                  <div class="text-right">
                    <!--                    <div class="mr-2">-->
                    <!--                      <button-->
                    <!--                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"-->
                    <!--                        data-wizard-type="action-prev"-->
                    <!--                      >Previous</button>-->
                    <!--                    </div>-->
                    <div>
                      <button
                          :disabled="!checked"
                          v-on:click="getEnrollmentStatus"
                          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-submit"
                      >Submit Application
                      </button>
                      <button
                          :disabled="!enable_save"
                          class="pull-right col-md-12 btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-next"
                          @click.prevent="nextClicked"
                      >Save & Continue
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
              </div>
              <!--end: Wizard-->
            </div>
          </div>
          <!--end: Wizard Body -->
        </div>
        <!--end: Wizard-->
      </div>
      <!-- <pre>{{enrollment}}</pre> -->
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>


<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.v-application--wrap {
  min-height: auto;
}

.form-control.form-control-solid {
  border: 1px solid #b3b3b3 !important;
}
</style>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
// Components
import ApplyingFor from "./content/ApplyingFor";
import PersonalInformation from "./content/PersonalInformation";
import AddressComponent from "./content/Address";
import ParentalInformation from "./content/ParentalInformation";
import Education from "./content/Education";
import AdditionalInformation from "./content/AdditionalInformation";
import Documents from "./content/Documents";
import PaymentMethod from "./content/PaymentMethod";
import SummaryComponent from "./content/Summary";
import ExamSchedule from "./content/ExamSchedule";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import UserService from "@/core/services/user/UserService";
// import UserEnrollemntService from '@/core/services/user/UserEnrollemntService';
import PreRegistrationSettingService from "../../../core/services/level/program/pre-registration-setting/PreRegistrationSettingService";
import UserInfoService from "@/core/services/user/UserInfoService";
import CartService from "@/core/services/cart/CartService";
import ProgramService from "@/core/services/level/program/ProgramService";
const preRegistrationSetting=new PreRegistrationSettingService();
const enrollmentService = new UserEnrollmentService();
const userService = new UserService();
const infoService = new UserInfoService();
const cartService = new CartService();
const programService = new ProgramService();

export default {
  name: "enrollment-apply",
  components: {
    ApplyingFor,
    PersonalInformation,
    AddressComponent,
    ParentalInformation,
    Education,
    AdditionalInformation,
    Documents,
    PaymentMethod,
    SummaryComponent,
    ExamSchedule
  },
  data() {
    return {
      enable_save: true,
      wizard: null,
      overlay: false,
      checked: false,
      step: 1,
      data: [],
      user: {},
      enrollment_status: {
        is_complete: true
      },
      payment_method: null,
      applying_for: null,
      enrollment: {
        first_name: null,
        middle_name: null,
        last_name: null,
        gender: "male",
        dob: null,
        dob_bs: null,
        phone: null,
        email: null,
        nationality: "nepalese",
        program_id: null,
        level_id: null,
        program: null,
        program_form_closing_date: null,
        pre_registration_id:'',
      }
    };
  },
  computed: {
  /*  enrollment_id() {
      return this.$route.params.id;
    },*/
    appliedProgram() {
      return this.$route.params.program;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Enrolments", route: "wizard-1"},
      {title: "Apply Online"}
    ]);

    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });
    // Validation before going to next page
    // wizard.on("beforeNext", function(obj) {
    //   console.log(obj.startStep)
    //   // validate the form and use below function to stop the wizard's step
    //   wizardObj.stop();
    // });

    // // Change event
    this.wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.getLoggedUser();
  },
  methods: {
    submit(payment_method) {
      Swal.fire({
        title: "",
        text:
            "The application has been submitted. Make sure to pay application charge by visiting the cart",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
      if (payment_method == "esewa") {
        this.$router.push({name: "cart"});
      } else {
        this.$router.push({name: "enrollment-list"});
      }
    },

    checkedTerms() {
      this.checked = true;
    },
    unchekedTerms() {
      this.checked = false;
    },
    convertAdToBs() {
      let data = {
        dob: this.enrollment.dob
      };
      enrollmentService
          .convertAdToBs(data)
          .then(response => {
            this.enrollment.dob_bs = response.data.dob;
          })
          .catch(error => {
            //console.log(error);
          });
    },
    nextClicked() {
      this.wizard.stop();
      this.step = this.wizard.getStep();
      if (this.step == 1) {
        this.$refs["applying_for"].validateForm();
      } else if (this.step == 2) {
        this.$refs["personal_detail"].validateForm();
        this.createEnrollment();
      } else if (this.step == 3) {
        this.$refs["address"].saveAddress();
        if (this.$refs["address"].checkAddress()) this.proceedToNextStep();
      } else if (this.step == 4) {
        this.$refs["education"].getEducationBackground();

        this.$refs["parental_info"].createParentInfo();
        if (this.$refs["parental_info"].checkParent()) this.proceedToNextStep();
      } else if (this.step == 5) {
        this.proceedToNextStep();
      } else if (this.step == 6) {
        this.$refs["additional_info"].createInfo();
      } else if (this.step == 8) {
        this.$refs["payment-method"].createOrUpdate();
        this.$refs["exam-schedule"].getInfo();
      } else if (this.step == 9) {
        this.$refs["exam-schedule"].createOrUpdate();
      } else if (this.step == 7) {
        this.proceedToNextStep();
      } else {
        this.proceedToNextStep();
      }
    },
    proceedToNextStep() {
      // this.resumeStep();

      if (this.step == 1) {
        this.resumeStep();
      } else if (this.step == 2) {
        this.resumeStep();
      } else if (this.step == 3) {
        this.resumeStep();
      } else if (this.step == 4) {
        this.resumeStep();
      } else if (this.step == 5) {
        this.resumeStep();
      } else if (this.step == 6) {
        this.wizard.goNext();
      } else if (this.step == 7) {
        this.resumeStep();
      } else if (this.step == 8) {
        this.wizard.goNext();
      } else {
        this.wizard.goNext();
      }
    },
    resumeStep() {
      alert('asd')
      this.wizard.resume();
      // if (this.enrollment.id) {
      //   this.getEnrollmentStatus();
      // }
    },
    toogleOverlay() {
      this.overlay = !this.overlay;
    },
    getLoggedUser() {
      userService
          .logged()
          .then(response => {
            this.user = response.data;
            this.enrollment.email = this.user.email;
            this.enrollment.phone = this.user.phone;
            this.enrollment.first_name = this.user.first_name;
            this.enrollment.last_name = this.user.last_name;
            this.getEnrollment();

            if (this.appliedProgram) {
              this.getProgramByAbstract();
              this.wizard.goTo(2);
              this.wizard.resume();
            }
          })
          .catch(error => {
            //console.log(error);
          });
    },
    getEnrollment() {
      if (this.enrollment_id) {
        enrollmentService
            .getById(this.enrollment_id)
            .then(response => {
              this.enrollment = response.data.enrollment;
              console.log(this.enrollment.id)
             /* this.resumeStep();*/

            })
            .catch(error => {
              //console.log(error);
            });
      }
    },
  /*  createPreregistrationSettings(){
      let data={
        program_id: this.enrollment.program_id,
        level_id: this.enrollment.level_id,
      };
      preRegistrationSetting
          .store(this.enrollment.program_id,data)
          .then(response => {
            if(response.data.status=="OK"){
              if(response.data.setting.id){
                this.enrollment.pre_registration_id=response.data.setting.id;
                this.createEnrollment();
              }
            }
          })
          .catch(error => {
            //console.log(error);
          });
    },*/
   createEnrollment(){
      enrollmentService
          .createEnrolmentInfo(this.enrollment)
          .then(response => {
            if(response.data.status=="OK"){
              if(response.data.enrollment){
                this.enrollment_id=response.data.enrollment.id;
                this.getEnrollment();
              }
            }
          })
    },
    getEnrollmentStatus() {
      enrollmentService
          .enrollmentStatus(this.user.id, this.enrollment.id)
          .then(response => {
            this.enrollment_status = response.data;
            if (
                this.enrollment_status.is_complete &&
                this.wizard.getStep() == 10
            ) {
              this.overlay = true;
              this.enrollment.is_submitted = 1;
              enrollmentService
                  .update(this.user.id, this.enrollment.id, this.enrollment)
                  .then(response => {
                    this.getProgram();
                    setTimeout(() => {
                      this.$router.push({ name: "enrollment-list" });
                    }, 3000);
                  });
            }
          });
    },

    addToCart(data) {
      cartService
          .addToCart(data)
          .then(response => {
            this.submit(data.payment_method);
          })
          .catch(error => {
            // console.log(error);
          });
    },
    getProgram() {
      programService.show(this.enrollment.program_id).then(response => {
        this.$refs['applying_for'].program = response.data.program
        this.buildCart(response.data.program);
      });
    },
    getProgramByAbstract() {
      programService.getByAbstract(this.appliedProgram).then(response => {
        this.enrollment.level_id = response.data.program.level_id;
        this.enrollment.program_id = response.data.program.id;
        this.enrollment.program_form_closing_date =
            response.data.program.form_closing_date;
        this.enrollment.program = response.data.program.short_title;
        this.getProgram();
      });
    },
    buildCart(program) {
      let cartData = {
        cart_type: "enrollment",
        item_price: program.entrance_exam_fee,
        user_enrollment_id: this.enrollment.id
      };
      this.getInfo(cartData);
    },
    getInfo(cartData) {
      infoService.getByUser(this.enrollment.id).then(response => {
        if (response.data.info) {
          let paymentMethod = response.data.info.payment_method;
          cartData.payment_method = paymentMethod;
        }
      });
      if (cartData.payment_method)
        this.addToCart(cartData);
    },
    setProgram(program) {
      this.enrollment.program_form_closing_date = program.form_closing_date;
      this.enrollment.program = program.short_title;
    },
    enableSaveButton() {
      if (this.enable_save) {
        this.enable_save = false;
      } else {
        this.enable_save = true;
      }
    }, openEligibility() {
      this.$refs['applying_for'].openDialog();
    }
  }
};
</script>
