<template>
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">Available entrance date</h4>
    <div class="row">
      <div class="col-md-12" v-if="entrances.length < 1">
          No entrance date are available at this moment. You can still submit your form. College administration will get back to you regarding dates and availability.
      </div>
      <div class="col-xl-12" v-for="(entrance,index) of entrances" :key="index" v-else>
        <input v-model="info.exam_date" type="radio" name="schedule_exam_time" class="form-check-input" :value="entrance.date" />
        <label>{{entrance.entrance_date}}</label>
      </div>

    </div>
    <!-- <pre>{{info}}</pre> -->
  </div>
</template>
<script>
import EntranceDateService from "@/core/services/level/entrance-date/EntranceDateService";
import UserInfoService from "@/core/services/user/UserInfoService";

const infoService = new UserInfoService();
const entranceDateService = new EntranceDateService()

export default {
      name:'exam-schedule',
      props:['enrollment'],
      data(){return{
        info:{},
        entrances:[]
      }},mounted(){
        this.getInfo();
        this.getEntranceDates()
      },
      methods:{
        getEntranceDates(){
        /*  entranceDateService.getByProgram(this.enrollment.program_id).then(response=>{
            this.entrances = response.data
          })*/
        },
          getInfo() {
      infoService.getByUser(this.enrollment.id).then(response => {
        if (response.data.info) {
          this.info = response.data.info;
        }
      });
    },
    createOrUpdate() {
      if (this.info.id) {
        this.updateInfo();
      } else {
        this.createInfo();
      }
    },
    createInfo() {
      this.$emit("toogle_overlay");
      infoService.store(this.enrollment.id, this.info).then(response => {
        if (response.data.info) this.info = response.data.info;
        this.$emit("toogle_overlay");
        this.$emit("validated");
      });
    },
    updateInfo() {
      this.$emit("toogle_overlay");
            delete this.info.payment_method
      infoService
        .update(this.enrollment.id, this.info.id, this.info)
        .then(response => {
          if (response.data.info) this.info = response.data.info;
          this.$emit("toogle_overlay");
          this.$emit("validated");
        });
    }
      }
}
</script>
