<template>
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">Applicant (student) information</h4>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <v-text-field outlined dense v-model="enrollment.first_name" label="First name"></v-text-field>
          <span
              class="form-text text-danger"
              v-if="$v.enrollment.first_name.$error"
          >This information is required</span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <v-text-field outlined dense v-model="enrollment.middle_name" label="Middle name"></v-text-field>
          <!-- <span class="form-text text-danger" v-if="$v.enrollment.first_name.$error">Please enter your middle name.</span> -->
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <v-text-field outlined dense v-model="enrollment.last_name" label="Last name"></v-text-field>
          <span
              class="form-text text-danger"
              v-if="$v.enrollment.last_name.$error"
          >This information is required</span>
        </div>
      </div>

    </div>
    <div class="row">

      <div class="col-md-6">
        <div class="form-group">
          <v-select outlined dense :items="genders" v-model="enrollment.gender" item-text="title"
                    item-value="value"></v-select>
          <span
              class="form-text text-danger"
              v-if="$v.enrollment.gender.$error"
          >This information is required</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label></label>
          <v-select label="Nationality" outlined dense :items="nationalities" v-model="enrollment.nationality"
                    item-text="title" item-value="value"></v-select>
          <span
              class="form-text text-danger"
              v-if="$v.enrollment.nationality.$error"
          >This information is required</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="col-12 p-0 form-group">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        dense
                        outlined
                        v-model="enrollment.dob"
                        v-bind="attrs"
                        v-on="on"
                        label="Date of birth in english date (AD)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="enrollment.dob"
                      @input="menu2 = false; convertAdToBs()"
                      header-color="primary"
                  ></v-date-picker>
                </v-menu>
              </div>

              <span
                  class="form-text text-danger"
                  v-if="$v.enrollment.dob.$error"
              >This information is required</span>
            </div>
            <div class="col-md-6">
              <v-text-field
                  outlined dense
                  type="text"
                  v-model="enrollment.dob_bs"
                  @focus="convertAdToBs"
                  label="Date of birth in nepali date (BS)"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="form-group">
          <v-text-field
              outlined dense
              type="text"
              v-model="enrollment.phone"
              label="Phone or mobile"
          />
          <span
              class="form-text text-danger"
              v-if="$v.enrollment.phone.$error"
          >This information is required</span>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="form-group">
          <v-text-field
              outlined
              dense
              type="email"
              v-model="enrollment.email"
              label="Email"
          />
          <span
              class="form-text text-danger"
              v-if="$v.enrollment.email.$error"
          >This information is required</span>
        </div>
      </div>
    </div>
    <!-- <pre>{{enrollment}}</pre> -->
  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "personal-information",
  props: ["enrollment"],
  validations: {
    enrollment: {
      first_name: {required},
      last_name: {required},
      gender: {required},
      dob: {required},
      dob_bs: {required},
      phone: {required},
      email: {required},
      nationality: {required}
    }
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      nepaliDate: "2053-09-19",
      menu: false,
      menu2: false,
      genders: [
        {value: "male", title: "Male"},
        {value: "female", title: "Female"},
        {value: "none", title: "Not disclosed"},
      ],
      nationalities: [
        {value: "nepalese", title: "Nepalese"},
        {value: "other", title: "Other"},
      ]
    };
  },
  methods: {
    validateForm() {
      this.$v.enrollment.$touch();
      if (this.$v.enrollment.$error) {
        setTimeout(() => {
          this.$v.enrollment.$reset();
        }, 5000);
      } else {
        this.$emit("validated");
      }
    },
    save(date) {

      this.$refs.menu.save(date);
    }
    ,
    convertAdToBs() {
      this.$emit("convertAdToBs");
    },


  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  }
};
</script>
<style scoped>
.datepicker {
  padding: 0px !important;
  width: 100% !important;
}
</style>
