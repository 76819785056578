<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12" v-for="(addr,index) of addresses" :key="index">
        <!-- <pre>{{addr}}</pre> -->
        <span class="badge badge-primary">{{ addr.type.toUpperCase() }} ADDRESS</span> <span class="fas fa-trash ml-2"
                                                                                             @click="deleteAddress(addr.id)"></span>
        <address class="mt-2 font-size-lg border p-5">
          {{ addr.address_line_1 != null && addr.address_line_1 != '' ? addr.address_line_1 + ', ' : '' }}
          <!--          {{ addr.address_line_2 != null && addr.address_line_2 != '' ? addr.address_line_2 + ', ' : '' }}-->
          {{ addr.area != null && addr.area != '' ? addr.area + ', ' : '' }}
          {{ addr.ward && addr.ward != '' ? 'Ward No. ' + addr.ward + ' ' : '' }}
          <br/>
          {{ addr.city && addr.city != '' ? addr.city + ', ' : '' }}
          {{ addr.state && addr.state != '' ? addr.state + ' ' : '' }} <br/>
          {{ addr.country && addr.country != '' ? addr.country + '' : '' }}
        </address>
      </div>
      <div class="col-12 text-right" v-if="!permanent_address_available && !show_form && addresses.length<2">
        <a
            href="#"
            class="btn btn-sm btn-outline-info"
            @click.prevent="show_form = true"
        >
          Add New Address
        </a>
      </div>
    </div>
    <div v-if="show_form">
      <h4 class="mt-5 mb-10 font-weight-bold text-dark">Applicant's Address</h4>
      <div class="row">
        <div class="row">
          <div class="col-xl-12">
            <div class="form-group">
              <label>Address Type</label>
              <select
                  name
                  id
                  class="border border-gray form-control form-control-solid form-control-lg"
                  v-model="address.type"
              >
                <!--                <option value>Select Address Type</option>-->
                <!--                <option value="temporary">Temporary</option>-->
                <option value="permanent" selected="selected">Permanent</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <!-- <pre>{{state}}</pre>
              <pre>{{district}}</pre>
              <pre>{{city}}</pre>-->
              <label>Province</label>
              <select
                  @change="getDistricts('permanent')"
                  name="province"
                  v-model="address.state"
                  class=" border border-gray form-control form-control-solid form-control-lg"
              >
                <option value="null">Province</option>
                <option
                    :value="province"
                    v-for="(province, index) of provinces"
                    :key="index"
                >{{ province.name }}
                </option>
              </select>
            </div>
            <span class="form-text text-danger" v-if="$v.address.state.$error">Please select province.</span>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>District</label>
              <select
                  @change="getMunicipalities('permanent')"
                  name="country"
                  v-model="address.district"
                  class="form-control form-control-solid form-control-lg"
              >
                <option value="null">District</option>
                <option :value="dis" v-for="(dis, index) of districts" :key="index">{{ dis.name }}</option>
              </select>

              <span
                  class="form-text text-danger"
                  v-if="$v.address.state.$error"
              >Please select district.</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Local Level</label>
              <select
                  name="country"
                  v-model="address.city"
                  class="form-control form-control-solid form-control-lg"
              >
                <option value="null">Local Level</option>
                <option :value="cty" v-for="(cty, index) of municipalities" :key="index">{{ cty.name }}</option>
              </select>
              <span
                  class="form-text text-danger"
                  v-if="$v.address.city.$error"
              >Please select local level.</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Ward No</label>
              <select
                  v-model="address.ward"
                  name
                  id
                  class="form-control form-control-solid form-control-lg"
              >
                <option value="null">Ward</option>
                <option :value="i" v-for="i of wards" :key="i">{{ i }}</option>
              </select>
              <span
                  class="form-text text-danger"
                  v-if="$v.address.ward.$error"
              >Please select your ward.</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Area/Tole</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="address1"
                  placeholder="Area"
                  v-model="address.area"
              />
              <span
                  class="form-text text-danger"
                  v-if="$v.address.area.$error"
              >Please enter your Area/Tole.</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Address</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="address1"
                  placeholder="Address Line 1"
                  value="Address Line 1"
                  v-model="address.address_line_1"
              />
              <span
                  class="form-text text-danger"
                  v-if="$v.address.address_line_1.$error"
              >Please enter your Address.</span>
            </div>
          </div>
          <!--          <div class="col-xl-4">-->
          <!--            <div class="form-group">-->
          <!--              <label>Address Line 2</label>-->
          <!--              <input-->
          <!--                  type="text"-->
          <!--                  class="form-control form-control-solid form-control-lg"-->
          <!--                  name="address2"-->
          <!--                  placeholder="Address Line 2"-->
          <!--                  value="Address Line 2"-->
          <!--                  v-model="address.address_line_2"-->
          <!--              />-->
          <!--              &lt;!&ndash; <span class="form-text text-danger">Please enter your Address.</span> &ndash;&gt;-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="col-xl-12">
            <div class="form-group">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="differentAddress"
                  @click="toggleDifferentAddress"
                  name="checkbox-1"
              >Do you have a temporary address?
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row" v-if="differentAddress">
          <div class="col-xl-12">
            <div class="form-group">
              <label>Address Type</label>
              <select
                  name
                  id
                  class="form-control form-control-solid form-control-lg"
                  v-model="temporary_address.type"
              >
                <!--                <option value>Select Address Type</option>-->
                <option value="temporary" selected="selected">Temporary</option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <!-- <pre>{{state}}</pre>
              <pre>{{district}}</pre>
              <pre>{{city}}</pre>-->
              <label>Province</label>
              <select
                  @change="getDistricts('temporary')"
                  name="procince"
                  v-model="temporary_address.state"
                  class="form-control form-control-solid form-control-lg"
              >
                <option value="null">Province</option>
                <option
                    :value="province"
                    v-for="(province, index) of provinces"
                    :key="index"
                >{{ province.name }}
                </option>
              </select>
            </div>
            <span class="form-text text-danger" v-if="$v.temporary_address.state.$error">Please select province.</span>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>District</label>
              <select
                  @change="getMunicipalities('temporary')"
                  name="country"
                  v-model="temporary_address.district"
                  class="form-control form-control-solid form-control-lg"
              >
                <option value="null">District</option>
                <option :value="dis" v-for="(dis, index) of temporary_districts" :key="index">{{ dis.name }}</option>
              </select>

              <span
                  class="form-text text-danger"
                  v-if="$v.temporary_address.state.$error"
              >Please select district.</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Local Level</label>
              <select
                  name="country"
                  v-model="temporary_address.city"
                  class="form-control form-control-solid form-control-lg"
              >
                <option value="null">Local Level</option>
                <option :value="cty" v-for="(cty, index) of temporary_municipalities" :key="index">{{ cty.name }}
                </option>
              </select>
              <span
                  class="form-text text-danger"
                  v-if="$v.temporary_address.city.$error"
              >Please select local level.</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Ward No</label>
              <select
                  v-model="temporary_address.ward"
                  name
                  id
                  class="form-control form-control-solid form-control-lg"
              >
                <option value="null">Ward</option>
                <option :value="i" v-for="i of wards" :key="i">{{ i }}</option>
              </select>
              <span
                  class="form-text text-danger"
                  v-if="$v.temporary_address.ward.$error"
              >Please select your ward.</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Area/Tole</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="address1"
                  placeholder="Area"
                  v-model="temporary_address.area"
              />
              <span
                  class="form-text text-danger"
                  v-if="$v.temporary_address.area.$error"
              >Please enter your Area/Tole.</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Address</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="address1"
                  placeholder="Address Line 1"
                  value="Address Line 1"
                  v-model="temporary_address.address_line_1"
              />
              <span
                  class="form-text text-danger"
                  v-if="$v.temporary_address.address_line_1.$error"
              >Please enter your Address.</span>
            </div>
          </div>
          <!--          <div class="col-xl-4">-->
          <!--            <div class="form-group">-->
          <!--              <label>Address Line 2</label>-->
          <!--              <input-->
          <!--                  type="text"-->
          <!--                  class="form-control form-control-solid form-control-lg"-->
          <!--                  name="address2"-->
          <!--                  placeholder="Address Line 2"-->
          <!--                  value="Address Line 2"-->
          <!--                  v-model="temporary_address.address_line_2"-->
          <!--              />-->
          <!--              &lt;!&ndash; <span class="form-text text-danger">Please enter your Address.</span> &ndash;&gt;-->
          <!--            </div>-->
          <!--          </div>-->


        </div>

        <!--        <div class="col-md-12 text-right">
                  <a
                      href="#"
                      class="col-md-12 btn btn-lg btn-success font-size-lg"
                      @click.prevent="saveAddress"
                  >
                    &lt;!&ndash;           &ndash;&gt;
                    <b>Save Address</b>
                  </a>
                </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AddressService from "@/core/services/user/UserAddressService";
// import CountryService from "@/core/services/address/CountryService";
// import StateService from "@/core/services/address/StateService";
// import CityService from "@/core/services/address/CityService";
import NepalAddressService from "@/core/services/address/NepalAddressService";

const addressService = new AddressService();
const nepalAddressService = new NepalAddressService();
// const countryService = new CountryService();
// const stateService = new StateService();
// const cityService = new CityService();

export default {
  name: "address-component",
  props: ["enrollment"],
  validations: {
    address: {
      address_line_1: {required},
      city: {required},
      area: {required},
      state: {required},
      country: {required},
      type: {required},
      ward: {required}
    },
    temporary_address: {
      address_line_1: {required},
      city: {required},
      area: {required},
      state: {required},
      country: {required},
      type: {required},
      ward: {required}
    }
  },
  data() {
    return {
      addresses: [],
      //   countries: [],
      provinces: [],
      districts: [],
      municipalities: [],
      temporary_provinces: [],
      temporary_districts: [],
      temporary_municipalities: [],
      state: null,
      district: null,
      city: null,
      show_form: true,
      sameAddress: false,
      differentAddress: false,
      permanent_address_available: false,
      wards: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32
      ],
      address: {
        address_line_1: null,
        // address_line_2: null,
        ward: null,
        city: null,
        area: null,
        district: null,
        state: null,
        country: "Nepal",
        type: "permanent"
      },
      temporary_address: {
        address_line_1: null,
        // address_line_2: null,
        ward: null,
        city: null,
        area: null,
        district: null,
        state: null,
        country: "Nepal",
        type: "temporary"
      }
    };
  },
  mounted() {
    this.getAddresses();
    this.getProvinces();

  },
  methods: {
    validateForm() {
      this.$v.address.$touch();
      if (this.$v.address.$error) {
        setTimeout(() => {
          this.$v.address.$reset();
        }, 5000);
      } else {
        this.$emit("validated");
      }
    },
    // getCountries() {
    //   countryService.all().then(response => {
    //     this.countries = response.data;
    //     this.getStates();
    //   });        console.log(this.provinces)
    //     stateService.all(this.address.country).then(response => {
    //         this.states = response.data;
    //     });
    // },
    // getCities() {
    //     cityService
    //         .all(this.address.country, this.address.state)
    //         .then(response => {
    //             this.cities = response.data;
    //         });
    // },
    getProvinces() {
      nepalAddressService.provinces().then(response => {
        this.provinces = response.data;
      });
    },
    getDistricts(type) {
      if (type === 'permanent') {
        nepalAddressService.districtsByProvince(this.address.state.id).then(response => {
          this.districts = response.data;
        });
      } else {
        nepalAddressService.districtsByProvince(this.temporary_address.state.id).then(response => {
          this.temporary_districts = response.data;
        });
      }

    },
    getMunicipalities(type) {

      if (type === 'permanent') {
        nepalAddressService
            .municipalitiesByDistrict(this.address.district.id)
            .then(response => {
              this.municipalities = response.data;
            });
      } else {
        nepalAddressService
            .municipalitiesByDistrict(this.temporary_address.district.id)
            .then(response => {
              this.temporary_municipalities = response.data;
            });
      }

    },
    resetAddress() {
      this.address = {
        address_line_1: null,
        // address_line_2: null,
        ward: null,
        city: null,
        area: null,
        state: null,
        district: null,
        country: "Nepal",
        type: "permanent"
      };
    },
    getAddresses() {
      addressService
          .paginate(this.enrollment.id)
          .then(response => {
            this.addresses = response.data.data;
            this.checkForPermanentAddress();
            if (this.addresses.length > 0 && this.addresses.length <= 2) {
              this.show_form = false;
            }
          })
          .catch(error => {
            //console.log(error);
          });
    },
    checkForPermanentAddress() {
      if (this.addresses.length > 0) {
        this.addresses.forEach(ele => {
          if (ele.type == "permanent") {
            this.permanent_address_available = true;
            this.show_form = false;
          }
        });
      } else {
        this.permanent_address_available = false;
        this.show_form = true;
      }
    },
    sameAsPermanent() {
      let data = {
        address_line_1: this.address.address_line_1,
        // address_line_2: this.address.address_line_2,
        city: this.address.city,
        area: this.address.area,
        district: this.address.district,
        state: this.address.state,
        country: this.address.country,
        type: "temporary"
      };

      addressService
          .store(this.enrollment.id, data)
          .then(response => {
            // console.log(response);
            this.$v.address.$reset();
            this.resetAddress();
            this.$emit("toogle_overlay");
            this.getAddresses();
          })
          .catch(error => {
            //console.log(error);
          });
    },

    checkAddress() {
      if(this.differentAddress)
        return this.addresses.length > 1;
      else
        return this.addresses.length > 0;
    },

    saveAddress() {

      if (this.differentAddress) {

        this.$v.address.$touch();
        this.$v.temporary_address.$touch();


        if (this.$v.address.$error || this.$v.temporary_address.$error) {
          setTimeout(() => {
            this.$v.address.$reset();
          }, 3000);
        } else {
          this.address.state = this.address.state.name;
          this.address.district = this.address.district.name;
          this.address.city = this.address.city.name;
          this.temporary_address.state = this.temporary_address.state.name;
          this.temporary_address.district = this.temporary_address.district.name;
          this.temporary_address.city = this.temporary_address.city.name;
          this.address.temporary_address = this.temporary_address;

          this.$emit("toogle_overlay");
          addressService
              .store(this.enrollment.id, this.address)
              .then(response => {
                this.$v.address.$reset();
                this.resetAddress();
                this.$emit("toogle_overlay");
                this.getAddresses();
                this.$emit("validated");

              })
              .catch(error => {
                //console.log(error);
              });
        }
      } else {

        this.$v.address.$touch();
        if (this.$v.address.$error) {
          setTimeout(() => {
            this.$v.address.$reset();
          }, 3000);
        } else {
          this.address.state = this.address.state.name;
          this.address.district = this.address.district.name;
          this.address.city = this.address.city.name;
          this.$emit("toogle_overlay");
          addressService
              .store(this.enrollment.id, this.address)
              .then(response => {
                this.$v.address.$reset();
                this.resetAddress();
                this.$emit("toogle_overlay");
                this.getAddresses();
                this.$emit("validated");

              })
              .catch(error => {
                //console.log(error);
              });
        }

      }

    },
    createAddress() {
      this.address.state = this.state.name;
      this.address.district = this.district.name;
      this.address.city = this.city.name;
      this.$v.address.$touch();
      if (this.$v.address.$error) {
        setTimeout(() => {
          this.$v.address.$reset();
        }, 3000);
      } else {
        this.$emit("toogle_overlay");
        addressService
            .store(this.enrollment.id, this.address)
            .then(response => {
              if (this.sameAddress == "same") {
                this.sameAsPermanent();
              } else {
                this.$v.address.$reset();
                this.resetAddress();
                this.$emit("toogle_overlay");
                this.getAddresses();
              }
            })
            .catch(error => {
              //console.log(error);
            });
      }
    },
    toggleDifferentAddress() {
      this.differentAddress = !this.differentAddress;

    },

    deleteAddress(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.$emit("toogle_overlay");

            addressService
                .delete(this.enrollment.id, id)
                .then(response => {
                  this.$snotify.success("Information deleted");
                  this.$v.address.$reset();
                  this.resetAddress();
                  this.$emit("toogle_overlay");
                  this.getAddresses();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    }
  }
};
</script>
